import type { ReadReferenceType } from '@readme/api/src/mappings/page/reference/types';

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Tooltip from '@ui/Tooltip';

import styles from './index.module.scss';

interface Props {
  /** The currently ingested source of API Definition */
  // @ts-ignore - TODO: remove when source is added to ReadReferenceType
  source: ReadReferenceType['data']['api']['source'];
}

const EndpointBanner = ({ source }: Props) => {
  const bem = useClassy(styles, 'EndpointBanner');

  const copy = useMemo(() => {
    switch (source) {
      case 'api':
      case 'form':
      case 'url':
        return {
          icon: 'oas',
          title: (
            <>
              This page is sourced from an{' '}
              <Link className={bem('-link')} to="/update/api-definition">
                OpenAPI definition
              </Link>
            </>
          ),
          subtitle: (
            <>
              You can make changes to the body content below, however all other changes must be made in your OpenAPI
              definition and then{' '}
              <Tooltip
                arrow={false}
                content={
                  <>
                    Certain incoming synced metadata (<code>title</code>, <code>excerpt</code>, <code>deprecated</code>{' '}
                    and <code>api</code>) will always overwrite any manual updates made from the ReadMe side.
                  </>
                }
              >
                <span className={bem('-tooltip')}>re-synced</span>
              </Tooltip>
              .
            </>
          ),
        };
      case 'bidi':
        return {
          icon: 'git-merge',
          title: 'This page is sourced from a Git respository.',
          subtitle: 'Changes will be bidirectionally synced with your Git repository.',
        };
      default:
        // Don't show the banner for other sources
        return null;
    }
  }, [bem, source]);

  if (!copy) {
    return null;
  }

  const { icon, title, subtitle } = copy;

  return (
    <Flex align="center" className={bem('&')} data-testid="endpoint-banner" gap="sm" justify="between">
      <Flex align="start" gap="sm" justify="start" layout="col">
        <Icon color="green40" name={icon} size="lg" />
        <Flex gap={0} layout="col">
          <span className={bem('-title')}>{title}</span>
          <span className={bem('-subtitle')}>{subtitle}</span>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EndpointBanner;

import type { ReadReferenceType } from '@readme/api/src/mappings/page/reference/types';

import { mockGuidesPageDocument } from './guidesPage';

/**
 * Mock reference page document.
 */
export const mockReferencePageDocument: ReadReferenceType['data'] = {
  ...mockGuidesPageDocument,
  type: 'endpoint',
  uri: '/versions/1.0.0/reference/test-slug',
  category: {
    uri: '/versions/1.0.0/categories/reference/Mock_API',
  },
  href: {
    dash: 'https://dash.readme.com/project/mock-project/v1.0.0/reference/test-slug',
  },
  api: {
    method: 'post',
    path: '/pet',
    schema: {
      openapi: '3.0.0',
      info: {
        title: 'Mock API',
        version: '1.0.0',
      },
      paths: {
        '/pet': {
          post: {
            tags: ['pet'],
            summary: 'Add a new pet to the store',
            description: '',
            operationId: 'addPet',
            parameters: [
              {
                name: 'api_key',
                in: 'header',
                required: false,
                schema: { type: 'string' },
              },
            ],
            requestBody: {
              description: 'Pet object that needs to be added to the store',
              content: {
                'application/json': {
                  schema: {
                    type: 'object',
                    properties: {
                      id: {
                        default: 90,
                        type: 'integer',
                        format: 'int64',
                        description: 'Request body description',
                      },
                      object_name: {
                        type: 'object',
                        required: ['nestedtag', 'nestedname', 'nested_object'],
                        properties: {
                          nestedname: {
                            type: 'string',
                            description: 'Name of the pet',
                          },
                          nestedtag: {
                            type: 'string',
                          },
                          nested_object: {
                            type: 'object',
                            properties: {
                              nested_nested_name: {
                                type: 'string',
                              },
                            },
                          },
                        },
                      },
                      tag: {
                        default: 'pizza the hut',
                        type: 'string',
                      },
                    },
                    required: ['tag'],
                  },
                },
              },
              required: true,
            },
            responses: {
              '200': {
                description: 'Pet added successfully',
                content: {
                  'application/json': {
                    schema: {
                      type: 'array',
                    },
                  },
                },
              },
              '405': {
                description: 'Invalid input',
              },
            },
            security: [
              {
                petstore_auth: ['write:pets', 'read:pets'],
              },
            ],
          },
        },
      },
    },
    // @ts-ignore - TODO: remove when source is added to ReadReferenceType
    source: 'form',
    uri: '/versions/1.0.0/reference/test-slug',
  },
};
